import React from 'react';
import { useTranslation } from 'react-i18next';
import Countdown, { zeroPad } from 'react-countdown';
import { IoMdRocket } from 'react-icons/all';

const launchTime = Date.UTC(2021, 10, 9, 19, 0, 0, 0);

console.log(new Date(launchTime).toUTCString());

const CountdownTimer: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center lg:items-end space-x-8 my-10">
      <div className="flex items-center ">
        <div>
          <IoMdRocket size={100} className="text-pink" />
        </div>
        <div className="flex flex-col text-base lg:text-2xl">
          <div className="font-super">{t('CountdownTimer.launchTime.text', 'Launching on')}</div>
          <div>{t('CountdownTimer.launchTime.time', 'November 9, 19:00 UTC')}</div>
        </div>
      </div>
      <div className="">
        <Countdown
          date={launchTime}
          renderer={({ days, hours, minutes, seconds }) => (
            <div className="flex font-bai text-base lg:text-6xl my-4 space-x-2 lg:space-x-4">
              <div className="flex flex-col items-center">
                <div className="flex">{zeroPad(days)}</div>
                <div className="text-sm lg:text-md">{t('CountdownTimer.minutes', 'Days')}</div>
              </div>
              <div className="pt-1">:</div>
              <div className="flex flex-col items-center">
                <div className="flex">{zeroPad(hours)}</div>
                <div className="text-sm lg:text-md">{t('CountdownTimer.minutes', 'Hours')}</div>
              </div>
              <div className="pt-1">:</div>
              <div className="flex flex-col items-center">
                <div className="flex">{zeroPad(minutes)}</div>
                <div className="text-sm lg:text-md">{t('CountdownTimer.minutes', 'Minutes')}</div>
              </div>
              <div className="pt-1">:</div>
              <div className="flex flex-col items-center">
                <div className="flex">{zeroPad(seconds)}</div>
                <div className="text-sm lg:text-md ">{t('CountdownTimer.seconds', 'Seconds')}</div>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default CountdownTimer;
